@use '@/styles/utils/mixins.scss' as *;

.meetTheTeamWrapper {
  .headingElement {
    position: relative;
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-family: 'KiaSignature';
      font-weight: 600;
      font-style: normal;
      &.titleXl {
        font-size: 60px;
        line-height: 60px;
        @include tab() {
          font-size: 48px;
          line-height: 60px;
        }
        @include mob() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleLg {
        font-size: 48px;
        line-height: 60px;
        @include tab() {
          font-size: 36px;
          line-height: 43.2px;
        }
      }
      &.titleMd {
        font-size: 24px;
        line-height: 36px;
        @include tab() {
          font-size: 20px;
          line-height: 24px;
        }
      }
      &.titleSm {
        font-size: 20px;
        line-height: 24px;
      }
      &.titleXs {
        font-size: 16px;
        line-height: 19.2px;
      }
    }
  }
  background-color: #f8f8f8;
  .meetTheTeamSlider {
    .swiper-wrapper {
      .swiper-slide {
        width: fit-content;
        @media screen and (min-width: 768px) {
          margin-right: 40px;
        }
        @media screen and (max-width: 480px) {
          max-width: 100%;
        }
      }
    }
  }
  .meetTheTeamSwiperControls {
    display: flex;
    align-items: center;
    justify-content: end;
    max-width: 1280px;
    width: 100%;
    margin: 40px auto 0 auto;
    @media screen and (max-width: 576px) {
      margin: 40px 0 0 auto;
    }

    &.showNavigation {
      padding-right: 0;
      @media screen and (min-width: 1024px) {
        display: flex;
      }
      @media screen and (max-width: 576px) {
        padding-left: 24px;
        padding-right: 24px;
      }
    }
  }
  .swiperNavigation {
    display: flex;
    align-items: center;
  }
  .swiperButtonNext1,
  .swiperButtonPrev1 {
    width: 24px;
    height: 24px;
    margin-left: 6px;
    svg {
      height: 100%;
      width: 100%;
    }
    @media screen and (max-width: 767px) {
      width: 48px;
      height: 48px;
    }
  }
  .swiperPagination {
    background-color: $keyline;
    border-radius: 15px;
    color: $kiaMidnightBlack;
    padding: 5px 10px;
    position: static;
    bottom: 0;
    right: 0;
    width: auto;
    left: auto;
    margin-right: 2px;
    line-height: 1;
    min-width: 48px;
    text-align: center;
    display: block;
    span {
      font-size: 12px;
      line-height: 14.4px;
    }
  }
}
